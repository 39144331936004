@media (max-width: 991px) {
  .input,
  .checkbox {
    font-size: 16px;
  }

  .checkbox input[type='checkbox'] {
    margin: 2px 0 0 -20px;
    width: 16px;
    height: 16px;
  }

  button.submit[type='submit'],
  .input input {
    width: 100%;
    font-size: 16px;
  }
  .danger {
    font-size: 16px;
    text-align: center;
  }
}
